import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

axios.defaults.baseURL = 'https://api.te.unjani.ac.id/api'
// axios.defaults.baseURL = 'http://localhost:8000/api'
// axios.defaults.baseURL = 'http://192.168.43.183:8000/api'

export default new Vuex.Store({
  state: {

    /* User */
    user: null,

    /* will deleted */
    userName: null,
    userEmail: null,
    userAvatar: 'https://avatars.dicebear.com/v2/gridy/John-Doe.svg',

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false
  },
  mutations: {
    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('user')
      location.reload()
    },

    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    },

    /* Aside Toogle */
    asideToggle (state, payload) {
      state.isAsideVisible = payload
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    }
  },
  actions: {
    login ({ commit }, credentials) {
      commit('setUserData', credentials)
    },

    logout ({ commit }) {
      commit('clearUserData')
    }
  },

  getters: {
    isLogged: state => !!state.user,
    currentRole: state => state.user.user.roles[0].name,
    currentRoleId: state => state.user.user.roles[0].id,
    currentKBK: state => state.user.user.kbk.length > 0 ? state.user.user.kbk[0] : false
  }
})
