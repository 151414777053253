export default [
  {
    meta: {
      title: 'Data Pengguna',
      auth: true,
      authorization: ['admin']
    },
    path: '/user',
    name: 'user',
    component: () => import('../views/user/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Pengguna',
      auth: true,
      authorization: ['admin']
    },
    path: '/user/create',
    name: 'user.create',
    component: () => import('../views/user/Form.vue')
  },
  {
    meta: {
      title: 'Edit Pengguna',
      auth: true,
      authorization: ['admin']
    },
    path: '/user/:id',
    name: 'user.edit',
    component: () => import('../views/user/Form.vue'),
    props: true
  }
]
