import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import mahasiswa from './mahasiswa'
import dosen from './dosen'
import thesis from './thesis'
import user from './user'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard',
      auth: true,
      authorization: ['admin', 'adminkbk', 'dosen']
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    meta: {
      title: 'Profile',
      auth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import('../views/pengaturan/Profile.vue')
  },
  ...mahasiswa,
  ...dosen,
  ...thesis,
  ...user
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }

  // handle authorization
  if (to.matched.some(record => record.meta.authorization)) {
    const authorization = to.meta.authorization
    const userInfo = localStorage.getItem('user')

    if (userInfo) {
      const userData = JSON.parse(userInfo)
      const currentRole = userData.user.roles[0].name

      const cek = authorization.indexOf(currentRole) >= 0
      if (!cek) {
        next('/')
      }

      // console.log(authorization)
      // console.log(currentRole)
      // console.log(cek)
    }
  }

  next()
})

export default router
