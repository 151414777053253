export default [
  {
    meta: {
      title: 'Data Dosen',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/dosen',
    name: 'dosen',
    component: () => import('../views/dosen/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Dosen',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/dosen/create',
    name: 'dosen.create',
    component: () => import('../views/dosen/Form.vue')
  },
  {
    meta: {
      title: 'Edit Dosen',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/dosen/:id',
    name: 'dosen.edit',
    component: () => import('../views/dosen/Form.vue'),
    props: true
  }
]
