<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>
    <section class="section is-main-section">
      <!-- <tiles>
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="account-multiple"
          :number="512"
          label="Clients"
        />
        <card-widget
          class="tile is-child"
          type="is-info"
          icon="cart-outline"
          :number="7770"
          prefix="$"
          label="Sales"
        />
        <card-widget
          class="tile is-child"
          type="is-success"
          icon="chart-timeline-variant"
          :number="256"
          suffix="%"
          label="Performance"
        />
      </tiles> -->

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Tugas Akhir
          </p>
          <b-field class="card-header-icon">
              <b-input placeholder="Search..."
                  type="search"
                  size="is-small"
                  icon="magnify"
                  v-model="searchWord"
                  @input="search(searchWord)">
              </b-input>
          </b-field>
        </header>
        <div class="card-content">
          <b-table
            :checked-rows.sync="checkedRows"
            :loading="isLoading"
            :paginated="paginated"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            :data="data"
          >
            <b-table-column label="Mahasiswa" field="email" sortable v-slot="props">
              {{ props.row.mahasiswa }}
            </b-table-column>
            <b-table-column label="Judul" field="name" sortable v-slot="props">
              {{ props.row.title }}
            </b-table-column>
            <b-table-column label="Pembimbing" field="email" sortable v-slot="props">
              {{ props.row.pembimbing1 }} {{ props.row.pembimbing2  === null ? '' : `- ${props.row.pembimbing2}` }}
            </b-table-column>
            <b-table-column label="Sidang" v-slot="props">
              <small class="has-text-grey is-abbr-like" :title="props.row.tanggal_sidang">{{ props.row.tanggal_sidang }} {{ props.row.sesi === null || props.row.tanggal_sidang === null ? '' : `- Sesi ${props.row.sesi}` }}</small>
            </b-table-column>
            <b-table-column label="Sebagai" v-slot="props" v-if="['admin','adminkbk'].indexOf(currentRole) === -1">
              <small class="has-text-grey is-abbr-like" :title="props.row.tanggal_sidang">{{ getPositionThesis(props.row) }}</small>
            </b-table-column>
            <b-table-column label="Status" v-slot="props">
              <span v-html="getStatus(props.row)"> </span>
            </b-table-column>
            <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
              <div class="buttons is-right">
                <router-link :to="{name:'thesis.show', params: {id: props.row.id}}" >
                  <b-button
                      label="Lihat"
                      type="is-success"
                      size="is-small"
                      icon-left="magnify" />
                </router-link>
                <router-link v-if="showEvaluationBtn(props.row)" :to="{name:'thesis.evaluation', params: {id: props.row.id}}">
                  <b-button
                      label="Penilaian"
                      type="is-warning"
                      size="is-small"
                      icon-left="book" />
                </router-link>
              </div>
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
// import Tiles from '@/components/Tiles'
// import CardWidget from '@/components/CardWidget'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    // CardWidget,
    // Tiles,
    HeroBar,
    TitleBar
  },
  data () {
    return {
      oriData: [],
      data: [],
      searchWord: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: []
    }
  },
  computed: {
    titleStack () {
      return ['Dashboard']
    },
    currentRole () {
      console.log(this.$store.getters.currentRole)
      return this.$store.getters.currentRole
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getStatus (row) {
      const status = [
        '<span class="tag is-info">Selesai</span>',
        '<span class="tag is-warning">Menunggu Jadwal Sidang</span>',
        '<span class="tag is-warning">Menunggu Penilaian</span>'
      ]

      if (!row.tanggal_sidang || !row.ketua_sidang_id || !row.penguji1_id || !row.penguji2_id) {
        return status[1]
      }

      if (!row.nilai_pembimbing || !row.nilai_penguji1 || !row.nilai_penguji2 || !row.nilai_ketua_sidang) {
        return status[2]
      }

      return status[0]
    },
    getPositionThesis (row) {
      const userID = parseInt(this.$store.state.user.user.id)
      if (parseInt(row.pembimbing1_id) === userID || parseInt(row.pembimbing2_id) === userID) return 'Pembimbing'
      if (parseInt(row.ketua_sidang_id) === userID) return 'Ketua Sidang'
      if (parseInt(row.penguji1_id) === userID) return 'Penguji 1'
      if (parseInt(row.penguji2_id) === userID) return 'Penguji 2'
    },
    showEvaluationBtn (row) {
      const position = this.getPositionThesis(row)

      if (this.currentRole === 'dosen') {
        if (position === 'Pembimbing' && row.nilai_pembimbing === null) return true
        if (position === 'Ketua Sidang' && row.nilai_ketua_sidang === null) return true
        if (position === 'Penguji 1' && row.nilai_penguji1 === null) return true
        if (position === 'Penguji 2' && row.nilai_penguji2 === null) return true
      }

      return false
    },
    getData () {
      this.isLoading = true
      axios
        .get('/thesis')
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            if (r.data.data.length > this.perPage) {
              this.paginated = true
            }
            this.data = r.data.data
            this.oriData = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    search (word) {
      if (word === null || word === '') {
        this.getData()
        return false
      }

      console.log(`search for ${word}`)
      const result = this.oriData.filter(obj => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(obj)) {
          // console.log(`${word} in ${value} ${String(value).toLowerCase().indexOf(word)}`)
          if (String(value).toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }
        }
      })

      this.data = result
    }
  }
}
</script>
