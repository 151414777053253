<template>
  <div id="app">
    <div v-if="isLogged">
      <nav-bar />
      <aside-menu :menu="menuFiltered" />
    </div>
    <router-view />
    <div v-if="isLogged">
      <footer-bar />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  data () {
    return {
      menu: [
        { label: 'General', authorization: ['admin', 'adminkbk', 'dosen'] },
        [
          {
            to: '/',
            icon: 'home',
            label: 'Dashboard'
          },
          {
            to: '/thesis',
            icon: 'book-open',
            label: 'Tugas Akhir',
            authorization: ['admin', 'adminkbk']
          }
        ],
        { label: 'Data', authorization: ['admin'] },
        [
          {
            to: '/dosen',
            icon: 'table',
            label: 'Dosen',
            authorization: ['admin']
          },
          {
            to: '/mahasiswa',
            icon: 'table',
            label: 'Mahasiswa',
            authorization: ['admin']
          }
        ],
        { label: 'Pengaturan', authorization: ['admin', 'adminkbk', 'dosen'] },
        [
          // {
          //   to: '/',
          //   icon: 'archive',
          //   label: 'Bobot TA'
          // },
          {
            to: '/user',
            icon: 'account-multiple',
            label: 'Pengguna',
            authorization: ['admin']
          },
          {
            to: '/profile',
            label: 'Profil',
            icon: 'account-circle'
          }
        ]
      ]
    }
  },
  computed: {
    isLogged () {
      return this.$store.getters.isLogged
    },
    menuFiltered () {
      if (this.$store.state.user) {
        const currentRole = this.$store.state.user.user.roles[0].name

        const newArr = this.menu.map((x) => {
          if (Array.isArray(x)) {
            return x.filter((y) => y.authorization ? y.authorization.indexOf(currentRole) >= 0 : true)
          } else {
            if (x.authorization && x.authorization.indexOf(currentRole) >= 0) {
              return x
            } else {
              return false
            }
          }
        })

        return newArr.filter((x) => (x))
      }

      return []
    }
  },
  created () {
    /* if client refresh browser */
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      this.$store.commit('setUserData', userData)
    }

    /* if token expired */
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  }
}
</script>
