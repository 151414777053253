export default [
  {
    meta: {
      title: 'Data Tugas Akhir',
      auth: true
    },
    path: '/thesis',
    name: 'thesis',
    component: () => import('../views/thesis/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Tugas Akhir',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/thesis/create',
    name: 'thesis.create',
    component: () => import('../views/thesis/Form.vue')
  },
  {
    meta: {
      title: 'Edit Tugas Akhir',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/thesis/:id',
    name: 'thesis.edit',
    component: () => import('../views/thesis/Form.vue'),
    props: true
  },
  {
    meta: {
      title: 'Penilaian',
      auth: true,
      authorization: ['dosen']
    },
    path: '/thesis/evaluation/:id',
    name: 'thesis.evaluation',
    component: () => import('../views/thesis/Evaluation.vue'),
    props: true
  },
  {
    meta: {
      title: 'Lihat',
      auth: true,
      authorization: ['admin', 'adminkbk', 'dosen']
    },
    path: '/thesis/show/:id',
    name: 'thesis.show',
    component: () => import('../views/thesis/Show.vue'),
    props: true
  }
]
